<template>
    <van-overlay class="public center" :show="show">
        <span class="title">请关注"康来家园"公众号，方便查看订单和浏览商品</span>
        <span class="desc">长按下面二维码进入关注页面</span>
        <van-image src="https://khome2.tuzuu.com/klfront/qrcode.jpg">
            <template v-slot:loading>
                <van-loading type="spinner" size="20" />
            </template>
        </van-image>
        <van-icon name="close" size="35" @click="clickHideOverlay" />
    </van-overlay>
</template>

<script>
export default {
    name: "PublicAccount",
    props: ["show"],
    methods: {
        clickHideOverlay() {
            this.$emit("hide");
        },
    },
};
</script>

<style lang="less" scoped>
.public {
    flex-direction: column;
    color: #fff;
    text-align: center;
    z-index: 555;
    .van-image {
        width: 300px;
        height: 300px;
        margin: 10px 0 20px;
        border-radius: 20px;
        overflow: hidden;
    }
    span {
        padding: 0 10px;
        box-sizing: border-box;
        margin-top: 5px;
    }
    .title {
        font-size: 16px;
    }
    .desc {
        font-size: 14px;
        color: #ddd;
    }
}
</style>
