<template>
    <div class="pay-success">
        <div class="pay-success-container" v-if="showPage">
            <div class="icon">
                <van-icon name="passed" color="#07c160" size="54"/>
                <span>支付成功</span>
            </div>

            <div class="update-container" v-if="data.recommend_goods && data.recommend_goods.length !== 0">
                <div class="update-content">
                    <div class="title">恭喜您获得1次升级机会</div>
                    <div class="label">用更低的价格，即可享受更高端的体检套餐</div>
                </div>

                <div class="update-list">
                    <van-checkbox-group v-model="checkedUpdateId" :max="1">
                        <van-checkbox
                            v-for="item in data.recommend_goods" 
                            :key="item.id" 
                            :name="item.id"
                            checked-color="#ee0a24"
                            @click="clickCheckbox(item)"
                        >
                            <van-card 
                                :thumb="item.thumb_pic" 
                                :title="item.title" 
                                :desc="item.check_items"
                                :lazy-load="true"
                            >
                                <template #price>
                                    +<span>¥<span style="font-size: 17px">{{ item.diff_price.split(".")[0] }}</span>.<span>{{ item.diff_price.split(".")[1] }}</span></span>升级
                                </template>
                                <template #bottom>
                                    <van-tag plain size="medium" type="primary" @click.stop="showDetail(item.title, item.id)">查看</van-tag>
                                </template>
                            </van-card>
                        </van-checkbox>
                    </van-checkbox-group>
                </div>
            </div>

            <div class="order-info" v-if="data.order">
                <div class="title">
                    订单信息
                </div>
                <div class="info">
                    <div class="info-item">
                        <div>已购商品</div>
                        <div>{{ data.order.title }}</div>
                    </div>
                    <div class="info-item">
                        <div>订单号</div>
                        <div>{{ data.order.sn }}</div>
                    </div>
                    <div class="info-item">
                        <div>下单时间</div>
                        <div>{{ data.order.created_at | filterTime }}</div>
                    </div>
                    <div class="info-item">
                        <div>购买份数</div>
                        <div>x {{ data.order.goods_number }}</div>
                    </div>
                    <div class="info-item" v-if="data.order.coin_num != 0">
                        <div class="item-title">K币抵扣</div>
                        <div>- {{data.order.coin_num}}</div>
                    </div>
                    <div class="info-item">
                        <div>支付金额</div>
                        <div>¥ {{ data.order.order_price }}</div>
                    </div>
                </div>
            </div>

            <GoodsDetailPopup
                :goodsDetailList="goodsDetailArr"
                v-model="showPopup"
                @onSubmit="onSubmit"
            >
                {{ showPopupTitle }}
            </GoodsDetailPopup>


            <van-submit-bar button-text="立即升级" @submit="onSubmit" v-if="checkedUpdateId[0]">
                <div class="text">
                    特惠：<span> ¥{{ getDataById.diff_price }}</span> 可升级
                </div>
            </van-submit-bar>

            <van-goods-action v-else>
                <van-goods-action-icon icon="home-o" text="首页" @click="backHome"/>
                <van-goods-action-icon icon="chat-o" text="咨询客服" @click="kefuShow = true"/>   
                <van-goods-action-button  type="danger" text="查看订单详情" @click="orderDetail" />
            </van-goods-action>

            <PublicAccount :show="showPublic" @hide="showPublic = false" />
            <ServicePopup :kf_qrcode="data.order.kf_qrcode" v-model="kefuShow" />

        </div>
        <van-empty image="https://khome2.tuzuu.com/klfront/newlogo.png" description="正在加载..." v-else />
    </div>
</template>s

<script>
import { getFormatDate, getGoodsDetail } from "@/utils/util";
import { weChatPay } from "@/wechat/weChatPay";
import GoodsDetailPopup from "@/components/GoodsDetailPopup";
import ServicePopup from "@/components/ServicePopup";
import PublicAccount from "@/components/PublicAccount";

export default {
    name: "PaySuccess",
    data: () => ({
        data: {},
        checkedUpdateId: [],
        isCancelPay: false,
        
        goodsDetailArr: [],
        showPage: false,
        kefuShow: false,
        showPopup: false,
        showPopupTitle: "",
        
        showPublic: false,
    }),
    components: {
        GoodsDetailPopup,
        ServicePopup,
        PublicAccount
    },
    async created() {
        this.order_sn = this.$route.query.order_sn;
        const res = await axios.get("/pay_done", {
            params: {
                order_sn: this.order_sn,
            }
        });
        if (res.data.is_subscribe != 1) {
            this.showPublic = true;
        }
        console.log(res.data);
        if (res.code == 1) {
            // 加载成功
            this.showPage = true;
            this.data = res.data;
        } else if (res.code == 201) {
            // 不是该用户的订单
            this.$router.replace("/home");
        } else if (res.code == 0) {
            // 订单未找到
            this.$dialog.alert({
                message: res.message
            }).then(() => {
                this.$router.replace("/my");
            })
        }
    },
    methods: {
        async onSubmit() {
            // MtaH5.clickStat('submitClick',{
            //     id:this.checkedUpdateId[0]
            // })
             window._czc.push(['_trackEvent','订单成功页', '提交订单id',,this.checkedUpdateId[0]])
            if (this.isCancelPay && this.checkedUpdateId[0] === this.cancelPayGoodsId) {
                console.log("直接支付");
                this.wxPay();
            } else {
                // 升级套装
                const res = await axios.put("/order_update", {
                    order_sn: this.order_sn,
                    goods_id: this.checkedUpdateId[0]
                });

                console.log("升级套装", res);
                if (res.code == 1) {
                    this.updateOrderSn = res.data.orderSn;

                    if (res.data.payConfig) {
                        // 需要补差价
                        this.wxPayConfig = res.data.payConfig;
                        this.wxPay();

                    } else {
                        // 不需要支付，查看升级之后的订单详情
                        const toast = this.$toast.success({
                            duration: 0,
                            forbidClick: true,
                            message: "升级成功",
                        })
                        setTimeout(() => {
                            toast.clear();
                            this.$router.push({
                                path: "/my/detail",
                                query: {
                                    order_sn: this.updateOrderSn,
                                }
                            })
                        }, 1500);
                    }
                }  else if (res.code != 5202) {
                    this.$toast(res.message);
                }
            }
        },
        wxPay() {
            weChatPay({
                wxPayConfig: this.wxPayConfig,
                onSuccess: () => {
                    this.wxPaySuccess();
                },
                onCancel: () => {
                    this.wxPayCancel();
                }
            });
        },
        wxPaySuccess() {
            this.isCancelPay = false;
            const toast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: '请稍候...',
            });
            setTimeout(() => {
                toast.type = "success";
                toast.message = "支付完成";
                setTimeout(() => {
                    toast.clear();
                    this.$router.push({
                        path: '/my/detail',
                        query: {
                            order_sn: this.updateOrderSn
                        }
                    })
                }, 1500);
            }, 700);
        },
        wxPayCancel() {
            this.isCancelPay = true;
            this.cancelPayGoodsId = this.checkedUpdateId[0];
        },
        async showDetail(title, id) {
            const toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
            })
            this.checkedUpdateId.splice(0, 1, id);
            this.showPopupTitle = title;
            const res = await axios.get("/goods_detail", {
                params: {
                    goods_id: id
                }
            })
            toast.clear();
            if (res.code != 1) {
                this.$toast(res.message);
                return;
            }
            this.goodsDetailArr = getGoodsDetail(res.data.goods[0]);
            this.showPopup = true;
        },
        clickCheckbox(data) {
            // MtaH5.clickStat('orderAndId',{
            //     id:data.id
            // })
              window._czc.push(['_trackEvent','订单成功页', '点击关联',,data.id])
            const updateGoodsId = this.checkedUpdateId[0];
            if (data.id === updateGoodsId) {
                return;
            } else {
                if (updateGoodsId) {
                    this.checkedUpdateId.splice(0, 1, data.id);
                }
            }
        },
        orderDetail() {
            // MtaH5.clickStat("showorderdetail")
             window._czc.push(['_trackEvent','订单成功页', '查看订单详情'])
            // 查看订单详情
            this.$router.push({
                path: "/my/detail",
                query: {
                    order_sn: this.data.order.sn,
                }
            })
        },
        backHome() {
            this.$router.push("/home")
        },
    },
    computed: {
        getDataById() {
            return this.data.recommend_goods.find(item => item.id === this.checkedUpdateId[0]);
        }
    },
    filters: {
        filterTime(time) {
            const d = new Date(time);
            return getFormatDate(new Date(d.toGMTString()));
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .van-checkbox__label{
    overflow: hidden;
}
.update-list /deep/ .van-card__desc{
    display: -webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    max-height:inherit !important;
    white-space:initial;
}
/deep/ .van-goods-action-icon{
    font-size: 12px !important;
}
.pay-success {
    width: 100%;
    height: 100%;
    color: #333;

    .van-empty {
        height: 100%;
        /deep/.van-empty__description {
            color: rgb(142,205,88);
            font-size: 15px;
            margin: 0;
        }
    }

    .pay-success-container {
        padding: 0px 8px 70px;
        box-sizing: border-box;

        .icon {
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
                font-size: 15px;
                margin-top: 10px;
            }
        }
        .update-container {
            box-shadow: 0 4px 16px 0 rgba(212, 227, 237, 0.32);
            border-radius: 10px;
            overflow: hidden;

            .update-content {
                padding: 15px;   
                background: linear-gradient(273deg, rgba(255, 225, 178, 0.6), rgba(255, 248, 237, 0.6));

                .title {
                    margin-bottom: 7px;
                    color: #c08b33;
                    font-size: 15px;
                    font-weight: bold;
                }
                .label {
                    color: #919298;
                    font-size: 12px;
                }
            }
            .update-list {
                padding: 0 10px;
            }
        }

        .van-checkbox {
            /deep/.van-checkbox__label {
                flex: 1;
            }
            .van-card {
                background: none;
                padding: 10px 0px;

                .van-card__thumb {
                    width: 137px;
                    height: 139px;
                }
                .van-card__bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                /deep/.van-card__title {
                    color: #000;
                    font-size: 14px;
                    -webkit-line-clamp: 1;
                }
                /deep/ .van-image__img {
                    box-shadow: 0px 2px 7px 2px rgba(246, 246, 246, 0.73);
                }
                .van-card__price {
                    color: #000;
                    font-size: 10px;
                    white-space: nowrap;
                    span {
                        color: #EC2627;
                        font-weight: bold;
                    }
                }
                .van-tag {
                    white-space: nowrap;
                }
            }
        }

        .van-submit-bar {
            .van-submit-bar__bar {
                justify-content: space-between;
                .text {
                    color: #000;
                    span {
                        color: #ff5b5b;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }
            .van-button {
                width: auto;
                white-space: nowrap;
                padding: 0 30px;
            }
        }

        .order-info {
            margin-top: 20px;
            box-shadow: 0 4px 16px 0 rgba(212, 227, 237, 0.32);
            border-radius: 10px;
            overflow: hidden;
            font-size: 14px;

            .title {
                padding: 15px; 
                color: #489ba8;
                font-weight: bold;
                font-size: 15px;  
                background: linear-gradient(273deg, rgba(173, 237, 245, 0.49), rgba(239, 254, 255, 0.49));
            }
            .info {
                padding: 10px 15px;
                box-sizing: border-box;

                .info-item {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 0px;
                    div:first-child {
                        white-space: nowrap;
                        margin-right: 15px;
                        font-weight: bold;
                    }
                    div:nth-of-type(2) {
                        word-wrap: break-word;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
</style>