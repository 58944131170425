<template>
    <van-popup v-model="showPopup" round position="bottom">
        <div class="title">
            <slot></slot>
        </div>
        <div class="content">
            <div class="item" v-for="(item, index) in goodsDetailList" :key="index">
                <div class="item-title">{{ item.title }}</div>
                <div class="item-content" v-html="item.content"></div>
            </div>
        </div>  
        <van-submit-bar
            button-text="立即升级"
            @submit="onSubmit"
            v-show="showPopup"
        >
        </van-submit-bar>
    </van-popup>
</template>

<script>
export default {
    name: "GoodsDetailPopup",
    props: ["goodsDetailList", "value"],
    methods: {
        onSubmit() {
            this.$emit('onSubmit')
        }
    },
    computed: {
        showPopup: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
}
</script>

<style lang="less" scoped>
.van-popup {
    max-height: 80%;
    height: 80%;
    padding: 20px 15px 50px;
    box-sizing: border-box;
    overflow: hidden;
    
    .van-submit-bar__bar {
        justify-content: center;
        .van-button {
            width: 100%;
        }
    }
    .title {
        text-align: center;
        font-weight: bold;
        font-size: 15px;
    }
    .content {
        margin-top: 20px;
        overflow: auto;
        height: calc(100% - 40px);
        
        .item {
            margin-bottom: 30px;

            .item-title {
                font-weight: bold;
                font-size: 15px;
            }
            .item-content {
                margin-top: 5px;
                font-size: 13px;
            }
            &:last-of-type {
                margin-bottom: 10px;
            }
            /deep/ img{
                max-width: 100% !important;
            }
        }
    }
}
</style>